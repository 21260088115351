import axios from "axios";

export async function sendSollicitatieMail(mail) {
    const url = process.env.REACT_APP_API_BASE_URL + "/mails/sollicitatie";

    try {
      return await axios.post(
        url,
        mail,
      )
    } catch (e) {
      console.log(e)
      return e;
    }
}

export function sendContactMail(mail) {
  const url = process.env.REACT_APP_API_BASE_URL + `/mails/contact`;

  const option = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      mail,
    })
  };

  return fetch(url, option)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  })
  .catch((err) => {
    console.log(err);
  });
}

const exportedFunctions = {
    sendSollicitatieMail,
    sendContactMail,
  };

export default exportedFunctions;
