import React from 'react'

import "../../styles/components/atoms/Loading.scss";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loading = () => {
  return (
    <div className='loading'>
        <AiOutlineLoading3Quarters />
    </div>
  )
}

export default Loading