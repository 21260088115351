import { React, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';

import { richTextToHtml } from '../util/text';
import { formatDate } from '../util/date';
import { bannerSkeleton,
        vacaturesReclameSkeleton,
        aanmeldenVacaturesSkeleton
      } from '../util/skeletons';

import "../styles/pages/HomePage.scss";

import Button from "../components/atoms/Button";
import VacaturesLink from '../components/molecules/VacaturesLink';
import KeepUpdated from '../components/molecules/KeepUpdated';
import DocumentHead from "../components/atoms/DocumentHead";
import { getContent } from '../services/ContentService';

const HomePage = () => {
  const navigate = useNavigate();
  const [homeBanner, setHomeBanner] = useState(bannerSkeleton);
  const [securityBanner, setSecurityBanner] = useState(bannerSkeleton);
  const [servicesBanner, setServicesBanner] = useState(bannerSkeleton);
  const [vacaturesReclame, setVacaturesReclame] = useState(vacaturesReclameSkeleton);
  const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton);
  const [nieuwsberichten, setNieuwsberichten] = useState([])

  useEffect(() => {
    getContent({
      content_type: 'homePage',
    })
      .then((response) => {
        setHomeBanner(response.items[0].fields.banner.fields)
        setSecurityBanner(response.items[0].fields.securityBanner.fields)
        setServicesBanner(response.items[0].fields.servicesBanner.fields)
        setVacaturesReclame(response.items[0].fields.vacaturesReclame.fields)
        setAanmeldenVacatures(response.items[0].fields.aanmeldenVacatures.fields)
      })
      .catch(console.error);

      getContent({
        content_type: 'nieuwsbericht',
        order: "-fields.datum",
        limit: 4,
      })
        .then((response) => {
            setNieuwsberichten(response.items)
        })
        .catch(console.error);
  }, [])

  return (
    <div className='home'>
      <DocumentHead
        title="Home | Ageras"
        metaDescription="Ageras is uw betrouwbare, professionele en betrokken partner voor Security en Services"
      />
      <div className='home-banner'>
        <div className='home-banner-image'>
          <img
            src={homeBanner.plaatje.fields.file.url}
            alt={homeBanner.plaatje.fields.description}
          />
        </div>

        <div className='home-banner-content'>
          <div className='home-banner-content-text'>
            <h1 className='home-banner-content-text-title'>{homeBanner.titel}</h1>
            {richTextToHtml(homeBanner.tekst.content, "home-banner-content-text-text")}
          </div>

          <div className='home-banner-content-footer'>
            <div className='home-banner-content-footer-button'>
              <Button
                color={"lightGrey"}
                onClick={() => {
                  navigate(homeBanner.primaireLink);
                }}
              />
            </div>

            <Link className='home-banner-content-footer-link' to={`/${homeBanner.secundaireLink[0]}`}>{homeBanner.secundaireLink[1]}</Link>
          </div>
        </div>
      </div>

      <div className='home-line'></div>

      <div className='home-services'>
        <div className='home-services-card'>
          <div className='home-services-card-image'>
            <img src={securityBanner.plaatje.fields.file.url} alt={securityBanner.plaatje.fields.description} />
          </div>

          <div className='home-services-card-content'>
            <h1 className='home-services-card-content-title'>{securityBanner.titel}</h1>
            {richTextToHtml(securityBanner.tekst.content, "home-services-card-content-text")}

            <div className='home-services-card-footer'>
              <div className='home-services-card-footer-button'>
                <Button
                  color={"lightGrey"}
                  onClick={() => {
                    navigate(securityBanner.primaireLink);
                  }}
                />
              </div>

              <Link className='home-services-card-footer-link' to={`/${securityBanner.secundaireLink[0]}`}>{securityBanner.secundaireLink[1]}</Link>
            </div>
          </div>
        </div>

        <div className='home-services-card'>
          <div className='home-services-card-image'>
            <img src={servicesBanner.plaatje.fields.file.url} alt={servicesBanner.plaatje.fields.description} />
          </div>

          <div className='home-services-card-content'>
            <h1 className='home-services-card-content-title--dark'>{servicesBanner.titel}</h1>
            {richTextToHtml(servicesBanner.tekst.content, "home-services-card-content-text--dark")}

            <div className='home-services-card-footer'>
              <div className='home-services-card-footer-button'>
                <Button
                  color={"darkGrey"}
                  onClick={() => {
                    navigate(servicesBanner.primaireLink);
                  }}
                />
              </div>

              <Link className='home-services-card-footer-link--dark' to={`/${servicesBanner.secundaireLink[0]}`}>{servicesBanner.secundaireLink[1]}</Link>
            </div>
          </div>
        </div>
      </div>

      <div className='home-line'></div>

      <div className='home-news'>
        <h1 className='home-news-title'>Nieuwsberichten</h1>
        <div className='home-news-grid'>
        {(nieuwsberichten.length === 0) ?
          <span>Er zijn op dit moment nog geen nieuwsberichten.<br/> Kom later terug om onze nieuwsberichten te bekijken!</span>
        :
        nieuwsberichten.map((item, i) => (
          <div
            key={i}
            className='home-news-item'
          >
            <div className='home-news-item-image'>
              <img
                src={item.fields.plaatje[0].fields.file.url}
                alt={item.fields.plaatje[0].fields.description}
                onClick={() => {
                  navigate(`/nieuwsberichten/${item.sys.id}`);
                }}
              />
            </div>
            <div className='home-news-item-inhoud'>
              <h1
                className='home-news-item-title'
                onClick={() => {
                  navigate(`/nieuwsberichten/${item.sys.id}`);
                }}
              >{item.fields.titel}</h1>
              <span className='home-news-item-datum'>{formatDate(item.fields.datum)}</span>
              <p className='home-news-item-text'>{item.fields.inleiding}</p>
              <Link
                className='home-news-item-link'
                to={`/nieuwsberichten/${item.sys.id}`}
              >
                Lees meer
              </Link>
            </div>
          </div>
        ))}
        </div>
        <Button
          title="Alle nieuwsberichten"
          color={"yellow"}
          onClick={() => {
            navigate("/nieuwsberichten");
          }}
        />
      </div>

      <VacaturesLink
        content={vacaturesReclame}
      />

      <KeepUpdated
        content={aanmeldenVacatures}
      />

    </div>
  )
}

export default HomePage
