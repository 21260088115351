import React, { useState, useRef, useEffect } from 'react'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";

import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

import "../styles/pages/ContactPage.scss";

import Loading from "../components/atoms/Loading";
import Button from "../components/atoms/Button";
import VacaturesLink from "../components/molecules/VacaturesLink";
import DocumentHead from "../components/atoms/DocumentHead";

import { telefoonRegex } from '../util/validate';
import { sendContactMail } from '../services/MailService';
import { richTextToHtml } from '../util/text';
import { contactPageSkeleton,
        vacaturesReclameSkeleton
        } from '../util/skeletons';

import contactBanner from "../assets/images/contact_banner.jpg";
import { getContent } from '../services/ContentService';

const contactValues = {
    naam: "",
    telefoon: "",
    email: "",
    bericht: "",
}

const ContactPage = () => {

    const recaptchaRef = useRef();

    const [contactForm, setContactForm] = useState(contactValues);
    const [contactSuccess, setContactSuccess] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState("")
    const [pageContent, setPageContent] = useState(contactPageSkeleton)
    const [vacaturesReclame, setVacaturesReclame] = useState(vacaturesReclameSkeleton);

    useEffect(() => {
        getContent({
          content_type: 'contactPage',
        })
          .then((response) => {
            setPageContent(response.items[0].fields)
            setVacaturesReclame(response.items[0].fields.vacaturesReclame.fields)
          })
          .catch(console.error);
    }, [])

    const handleOnChangeContact = (e) => {
        const { name } = e.target;
        let { value } = e.target;

        setContactForm({
            ...contactForm,
            [name]: value
        })
    }

    const contactSchema = yup.object().shape({
        naam: yup.string()
          .required("Vul een naam in"),
        email: yup.string()
          .email("Vul een geldig email adres in")
          .required("Vul een email adres in"),
        telefoon: yup.string()
          .nullable()
          .default(undefined)
          .transform((value) => value === "" ? null : value)
          .matches(telefoonRegex, "Vul een geldig telefoonnummer adres in"),
        bericht: yup.string()
          .required("Vul een bericht in"),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(contactSchema),
    })

    const handleOnSubmitContact = async (data) => {
        setContactLoading(true);
        setRecaptchaError("");

        const token = await recaptchaRef.current.getValue();
        if (!token) {
            setRecaptchaError("Vul de recaptcha in");
            setContactLoading(false);
            return;
        }

        data = {...data, token}
        sendContactMail(JSON.stringify(data)).then(async result => {
            if (result) {
                reset();
                setContactForm(contactValues);
                setContactSuccess(true);
                setContactLoading(false);
                await recaptchaRef.current.reset();

                setTimeout(() => {
                    setContactSuccess(false);
                }, 10000);
                return;
            }

            setContactError(true);
            setContactLoading(false);
        })

    }

  return (
    <div className='contact'>
        <DocumentHead
            title="Contact | Ageras"
            metaDescription=""
        />
        <div className='contact-banner'>
            <div className='contact-banner-image'>
                <img src={contactBanner} alt='' />
            </div>

            <div className='contact-banner-content'>
                <div>
                    <h1>{pageContent.titel}</h1>
                    <ul className='contact-banner-list'>
                        <li className='contact-banner-list-item'>
                            <AiOutlineMail className='contact-banner-list-item-icon' />
                            <a href={`mailto:${pageContent.email}`}>{pageContent.email}</a>
                        </li>
                        <li className='contact-banner-list-item'>
                            <AiOutlinePhone className='contact-banner-list-item-icon' />
                            <a href={`tel:${pageContent.telefoon}`}>{pageContent.telefoon}</a>
                        </li>
                    </ul>

                    <h3 className='contact-banner-subtitle'>{pageContent.adresTitel}</h3>
                    <p className='contact-banner-text'>{pageContent.adres}</p>
                    {pageContent.overigeInformatie && richTextToHtml(pageContent.overigeInformatie.content, "contact-banner-text")}
                </div>
                <div className='contact-banner-content-form'>
                    <form
                        method='POST'
                        onSubmit={handleSubmit(handleOnSubmitContact)}
                    >
                        {
                            (contactSuccess) ?
                            (
                                <>
                                    <h1 className='contact-form-title'>Je hebt succesvol contact opgenomen!🎉</h1>
                                    <h3>We behandelen je mail zo snel mogelijk.</h3>
                                </>
                            )
                            :
                            (
                                <>
                                    <h1 className='contact-form-title--yellow'>Neem contact op</h1>
                                    {(contactError) && (
                                        <h3 className='contact-form-error'>Oops! Er is iets foutgegaan.</h3>
                                    )}
                                </>
                            )
                        }
                        <div className='contact-form-section'>
                            <div className='contact-form-section-text'>
                                <label>Naam*:</label>
                                <p className='contact-form-error'>{errors.naam?.message}</p>
                            </div>
                            <input
                                type="text"
                                name="naam"
                                {...register("naam")}
                                onChange={(e) => {
                                    handleOnChangeContact({target: {name: e.target.name, value: e.target.value}})
                                }}
                            />
                        </div>
                        <div className='contact-form-section'>
                            <div className='contact-form-section-text'>
                                <label>Email*:</label>
                                <p className='contact-form-error'>{errors.email?.message}</p>
                            </div>
                            <input
                                type="text"
                                name="email"
                                {...register("email")}
                                onChange={(e) => {
                                    handleOnChangeContact({target: {name: e.target.name, value: e.target.value}})
                                }}
                            />
                        </div>
                        <div className='contact-form-section'>
                            <div className='contact-form-section-text'>
                                <label>telefoon:</label>
                                <p className='contact-form-error'>{errors.telefoon?.message}</p>
                            </div>
                            <input
                                type="tel"
                                name="telefoon"
                                {...register("telefoon")}
                                onChange={(e) => {
                                    handleOnChangeContact({target: {name: e.target.name, value: e.target.value}})
                                }}
                            />
                        </div>
                        <div className='contact-form-section'>
                            <div className='contact-form-section-text'>
                                <label>Bericht*:</label>
                                <p className='contact-form-error'>{errors.bericht?.message}</p>
                            </div>
                            <textarea
                                name="bericht"
                                {...register("bericht")}
                                onChange={(e) => {
                                    handleOnChangeContact({target: {name: e.target.name, value: e.target.value}})
                                }}
                            ></textarea>
                        </div>

                        <p className='vacature-form-error'>{recaptchaError}</p>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        />

                        <div className='contact-form-buttons'>
                            {
                                (contactLoading) ?
                                (
                                    <Button
                                        icon={<Loading />}
                                        onlyText={true}
                                        color="lightGrey"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )
                                :
                                (contactSuccess) ?
                                (
                                    <Button
                                        icon={
                                        <svg className="button-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <path className="button-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                        </svg>
                                        }
                                        onlyText={true}
                                        color="green"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )
                                :
                                (
                                    <Button
                                        title="Verstuur"
                                        color="lightGrey"
                                        onClick={() => {
                                            setContactSuccess(false);
                                            setContactError(false);
                                        }}
                                    />
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <VacaturesLink
            content={vacaturesReclame}
        />
    </div>
  )
}

export default ContactPage
