import { React, useState, useEffect } from 'react'
import { Link } from "react-router-dom"

import { formatDate } from '../util/date';

import "../styles/pages/NieuwsberichtenPage.scss";

import KeepUpdatedNews from '../components/molecules/KeepUpdatedNews';
import DocumentHead from "../components/atoms/DocumentHead";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { aanmeldenVacaturesSkeleton } from '../util/skeletons';
import { getContent } from '../services/ContentService';

const NieuwsberichtenPage = () => {

    const PAGESIZE = 10
    const [totalPages, setTotalPages] = useState(1)
    const [titel, setTitel] = useState()
    const [nieuwsberichten, setNieuwsberichten] = useState([])
    const [socialMedia, setSocialMedia] = useState([])
    const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton)

    useEffect((e) => {
        loadArticles(e, 1)

        getContent({
            content_type: 'homePagina',
        })
        .then((response) => {
            setTitel(response.items[0].fields.titel)
            setSocialMedia(response.items[0].fields.socials)
            setAanmeldenVacatures(response.items[0].fields.aanmeldenNieuwsberichten.fields)
        })
        .catch(console.error);
    }, [])

    const loadArticles = (e, value) => {
        getContent({
            content_type: 'nieuwsbericht',
            order: "-fields.datum",
            skip: PAGESIZE*(value-1),
            limit: PAGESIZE,
        })
        .then((response) => {
            setNieuwsberichten(response.items)
            setTotalPages(Math.ceil(response.total/PAGESIZE))
        })
        .catch(console.error);
    };

  return (
    <div className='nieuwsberichten'>
        <DocumentHead
            title="Nieuwsberichten | Ageras"
            metaDescription="Bekijk onze laatste nieuwsberichten en volg ons op de sociale media!"
        />
        <h1 className='nieuwsberichten-titel'>{titel}</h1>
        <div className='nieuwsberichten-artikelen'>
            {
                (nieuwsberichten.length === 0) ?
                    <span>Op dit moment zijn er nog geen nieuwsberichten.<br/>Kom later terug om onze nieuwsberichten te bekijken!</span>
                :
                nieuwsberichten.map((item, index) => (
                    <div className='nieuwsberichten-artikelen-item' key={index}>
                        <Link to={`/nieuwsberichten/${item.sys.id}`}>
                            <img src={item.fields.plaatje[0].fields.file.url} alt={item.fields.plaatje[0].fields.description} className='nieuwsberichten-artikelen-item-banner' />
                        </Link>
                        <div className='nieuwsberichten-artikelen-item-inhoud'>
                            <Link to={`/nieuwsberichten/${item.sys.id}`} className='nieuwsberichten-artikelen-item-titel'>{item.fields.titel}</Link>
                            <span className='nieuwsberichten-artikelen-item-datum'>{formatDate(item.fields.datum)}</span>
                            <p className='nieuwsberichten-artikelen-item-paragraaf'>{item.fields.inleiding}</p>
                            <Link to={`/nieuwsberichten/${item.sys.id}`} className='nieuwsberichten-artikelen-item-link'>Lees meer</Link>
                        </div>
                    </div>
                ))
            }
            <div className='nieuwsberichten-artikelen-pagination'>
                <Stack spacing={2}>
                    <Pagination count={totalPages} onChange={loadArticles} />
                </Stack>
            </div>
        </div>

        <div className='nieuwsberichten-socials'>
            <KeepUpdatedNews
                content={aanmeldenVacatures}
            />
            <div>
                <h1 className='nieuwsberichten-socials-titel'>Volg ons op</h1>
                <div className='nieuwsberichten-socials-items'>
                    {
                        socialMedia.map((item, index) => (
                            <a href={item.fields.link} rel="noreferrer" target='_blank' className="nieuwsberichten-socials-item-link" key={index}>
                                <div className='nieuwsberichten-socials-item' key={index}>
                                    <img className="nieuwsberichten-socials-item-logo" src={item.fields.logo.fields.file.url} alt={item.fields.logo.fields.description} />
                                    {item.fields.titel}
                                </div>
                            </a>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default NieuwsberichtenPage
