export const bannerSkeleton = {
    pagina: "",
    titel: "",
    tekst: {
      content: [],
    },
    plaatje: {
      fields: {
        file: {
          url: "",
        },
        description: "",
      },
    },
    secundairPlaatje: {
      fields: {
        file: {
          url: "",
        },
        description: "",
      },
    },
    primaireLink: [],
    secundaireLink: [],
}

export const pageSkeleton = {
    inhoudTitel: "",
    inhoudTekst: {
      content: [],
    },
    inhoudPlaatje: {
      fields: {
        file: {
          url: "",
        },
        description: "",
      },
    },
    bedrijvenTitel: "",
    bedrijvenLogos: [],
}

export const overOnsPageSkeleton = {
    teamTitel: "",
    personen: [],
    sponserTitel: "",
    sponserLogos: [],
    steunTitel: "",
    steunLogos: [],
    zakelijkTitel: "",
    zakelijkAgerasSecurity: {
        content: [],
    },
    zakelijkAgerasServices: {
        content: [],
    },
}

export const contactPageSkeleton = {
    titel: "",
    email: "",
    telefoon: "",
    adresTitel: "",
    adres: "",
}

export const vacaturesReclameSkeleton = {
    titel: "",
    tekst: {
      content: [],
    },
    link: [],
}

export const aanmeldenVacaturesSkeleton = {
    titel: "",
    placeholder: "",
    succesTitel: "",
    succesTekst: "",
}

export const vacatureSkeleton = {
  titel: "",
  datum: "",
  plaats: "",
  type: "Security",
  uren: "",
  watGaJijDoen: {
      content: []
  },
  gewenstProfiel: [],
  watWijBieden: [],
}

export const nieuwsberichtSkeleton = {
  titel: "",
  datum: "",
  alinea1: {
    content: []
  },
  alinea2: {
    content: []
  },
  plaatje: [],
}
