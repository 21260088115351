import React, {useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { richTextToHtml } from '../util/text';
import { formatDate } from '../util/date';
import { bannerSkeleton,
         aanmeldenVacaturesSkeleton
        } from '../util/skeletons';

import { GrPowerReset } from "react-icons/gr";
import { IoIosTime } from "react-icons/io"
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa"
import { GiHandTruck } from "react-icons/gi"

import "../styles/pages/VacaturesPage.scss";

import Button from '../components/atoms/Button';
import KeepUpdated from "../components/molecules/KeepUpdated";
import Loading from "../components/atoms/Loading";
import Input from '../components/atoms/Input';
import DocumentHead from "../components/atoms/DocumentHead";
import { getContent } from '../services/ContentService';

const VacaturesPage = () => {

  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));
  const navigate = useNavigate();
  const vacaturesRef = useRef(null);

  const [vacaturesBanner, setVacaturesBanner] = useState(bannerSkeleton);
  const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton);
  const [vacatureType, setVacatureType] = useState("");
  const [vacatureTitle, setVacatureTitle] = useState("");
  const [searchedTitle, setSearchedTitle] = useState("");
  const [vacatures, setVacatures] = useState([]);
  const [loading, setLoading] = useState(false);

  const addRemoveFilter = useCallback((type, title) => {
    setLoading(true);

    getContent({
      content_type:"vacature",
      order: "-fields.datum",
      'fields.type[in]': type ? type : 'Security,Services',
      ...(title && { 'fields.titel[match]': `*${title}*` }),
    })
      .then((response) => {
        setVacatures(response.items)
        if (queryParams.get("scroll")) {
          scrollToVacatures();
          queryParams.delete("scroll");
        }
      })
      .catch(console.error);

    setLoading(false);
    setVacatureTitle("");
    const urlType = (type) ? `type=${type}` : ``
    const urlTitle = (title) ? `&title=${title}` : ``
    navigate({
      pathname: "/vacatures",
      search: "?" + new URLSearchParams(`${urlType}${urlTitle}`)
    })
  }, [navigate, queryParams])

  const scrollToVacatures = () => {
    vacaturesRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    setQueryParams(new URLSearchParams(search));
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll(".no-animation").forEach(item => {
        item.classList.remove("no-animation");
      });
    }, 500);

    getContent({
      content_type:"vacaturesPagina"
    })
      .then((response) => {
        setVacaturesBanner(response.items[0].fields.banner.fields)
        setAanmeldenVacatures(response.items[0].fields.aanmeldenVacatures.fields)
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    setVacatureType(queryParams.get("type"));
    setSearchedTitle(queryParams.get("title"));

    addRemoveFilter(queryParams.get("type"), queryParams.get("title"))
  }, [addRemoveFilter, queryParams]);

  return (
    <div className='vacatures'>
      <DocumentHead
        title="Vacatures | Ageras"
        metaDescription="Word jij nu al enthousiast om bij Ageras aan de slag te gaan? Wij kijken er naar uit jou te ontmoeten!"
      />
        <div className='vacatures-banner'>
        <div className='vacatures-banner-image no-animation'>
          <img className={`vacatures-banner-image${vacatureType === "Services" ? "--open" : "--close"}`} src={vacaturesBanner.secundairPlaatje.fields.file.url} alt={vacaturesBanner.secundairPlaatje.fields.description} />
          <img className={`vacatures-banner-image${vacatureType !== "Services" ? "--open" : "--close"}`} src={vacaturesBanner.plaatje.fields.file.url} alt={vacaturesBanner.plaatje.fields.description} />
        </div>

        <div className='vacatures-banner-content'>
          <div className='vacatures-banner-content-text'>
            <h1 className='vacatures-banner-content-text-title'>{vacaturesBanner.titel}</h1>
            {richTextToHtml(vacaturesBanner.tekst.content, "security-banner-content-text-text")}
          </div>

          <div className='vacatures-banner-content-footer'>
            <div className='vacatures-banner-content-footer-button'>
              <Button
                color={"lightGrey"}
                onClick={() => {
                  scrollToVacatures()
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div ref={vacaturesRef} className='vacatures-filter-container'>
        <div className='vacatures-filters'>
            <Button
              icon={<GrPowerReset />}
              onlyText={true}
              color="lightGrey"
              onClick={() => {
                setVacatureType("");
                setSearchedTitle("");
                addRemoveFilter();
              }}
            />
            <Button
              title="Security"
              onlyText={true}
              color={(vacatureType === "Security") ? "yellow" : "lightGrey"}
              onClick={() => {
                setVacatureType("Security")
                addRemoveFilter("Security", searchedTitle);
              }}
            />
            <Button
              title="Services"
              onlyText={true}
              color={(vacatureType === "Services") ? "yellow" : "lightGrey"}
              onClick={() => {
                setVacatureType("Services")
                addRemoveFilter("Services", searchedTitle);
              }}
            />
            <div className='vacatures-filters-input'>
              <Input
                value={vacatureTitle}
                placeholder="Zoek naar vacatures"
                onChange={(e) => { setVacatureTitle(e.target.value); }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && vacatureTitle !== "") {
                    setSearchedTitle(vacatureTitle);
                    addRemoveFilter(vacatureType, vacatureTitle);
                }
                }}
                onClick={() => {
                  setSearchedTitle(vacatureTitle);
                  addRemoveFilter(vacatureType, vacatureTitle);
                }}
              />
            </div>
        </div>

        {
          (searchedTitle) && (<h1 className='vacatures-filters-zoekterm'>Gezocht op: {searchedTitle}</h1>)
        }
      </div>

      {
        (loading) ?
        (
          <Loading />
        )
        : (vacatures.length > 0) ? (
        <div className='vacatures-overzicht'>
          {vacatures.map((vacature, i) => (
            <div className='vacatures-card' key={i}>
              <div className='vacatures-card-content'>
                <h1 className='vacatures-card-title'>{vacature.fields.titel}</h1>

                <ul className='vacatures-card-list'>
                    <li className='vacatures-card-list-item'>
                        <FaMapMarkerAlt />
                        <p>{vacature.fields.plaats}</p>
                    </li>
                    <li className='vacatures-card-list-item'>
                        {(vacature.type === "Services") ? <GiHandTruck /> : <AiFillSafetyCertificate />}
                        <p>{vacature.fields.type}</p>
                    </li>
                    <li className='vacatures-card-list-item'>
                        <IoIosTime />
                        <p>{vacature.fields.urenPerWeek} uur per week</p>
                    </li>
                </ul>
                {richTextToHtml(vacature.fields.watGaJijDoen.content, "vacatures-card-beschrijving")}
                </div>

                <div className='vacatures-card-footer'>
                    <p>{formatDate(vacature.fields.datum)}</p>
                    <Button
                      color="lightGrey"
                      onClick={() => {
                        navigate(`/vacatures/${vacature.sys.id}`)
                      }}
                    />
                </div>
              </div>
            ))
          }
        </div>
      )
      :
      (
        <h1 className='vacatures-geen-resultaten'>Oops! Er zijn geen vacatures gevonden.</h1>
      )
      }

      <KeepUpdated
        content={aanmeldenVacatures}
      />

    </div>
  )
}

export default VacaturesPage
