export function getContent(parameters) {
    const url = process.env.REACT_APP_API_BASE_URL + "/content"
    const option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        parameters
      })
    };
    return fetch(url, option)
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
    })
    .then((data) => {
        return data;
    })
    .catch((err) => {
        console.log(err);
    });
  }

  const exportedFunctions = {
      getContent,
    };

  export default exportedFunctions;
