export function addUser(email, type) {
  let url = ""
  switch(type) {
    case "vacatures":
      url = process.env.REACT_APP_API_BASE_URL + "/users/vacatures";
      break
    case "news":
      url = process.env.REACT_APP_API_BASE_URL + "/users/news";
      break
    default:
      return false
  }

  const option = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email
    })
  };

  return fetch(url, option)
    .then((response) => {
      return response.status
    })
    .catch((err) => {
      console.log(err);
    });
}

export function removeUser(id, code, type) {
  const url = process.env.REACT_APP_API_BASE_URL + `/users/remove`;

  const option = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      code,
      type: (type === "nieuwsberichten") ? "news" : (type === "vacatures") ? "vacatures" : null
    })
  };

  return fetch(url, option)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  })
  .catch((err) => {
    console.log(err);
  });
}

const exportedFunctions = {
    addUser,
    removeUser,
  };

export default exportedFunctions;
