import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { richTextToHtml } from '../util/text';

import { bannerSkeleton,
        pageSkeleton,
        vacaturesReclameSkeleton,
        aanmeldenVacaturesSkeleton
      } from '../util/skeletons';

import "../styles/pages/SecurityPage.scss";

import VacaturesLink from '../components/molecules/VacaturesLink';
import KeepUpdated from "../components/molecules/KeepUpdated";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";
import { getContent } from '../services/ContentService';

const SecurityPage = () => {

  const navigate = useNavigate();
  const [securityBanner, setSecurityBanner] = useState(bannerSkeleton);
  const [pageContent, setPageContent] = useState(pageSkeleton)
  const [vacaturesReclame, setVacaturesReclame] = useState(vacaturesReclameSkeleton);
  const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton);

  useEffect(() => {
    getContent({
      content_type: 'securityPagina',
    })
      .then((response) => {
        setPageContent(response.items[0].fields)
        setSecurityBanner(response.items[0].fields.banner.fields)
        setVacaturesReclame(response.items[0].fields.vacaturesReclame.fields)
        setAanmeldenVacatures(response.items[0].fields.aanmeldenVacatures.fields)
      })
      .catch(console.error);
  }, [])

  return (
    <div className='security'>
      <DocumentHead
        title="Security | Ageras"
        metaDescription="Ageras Security is een enthousiaste beveiligingsorganisatie die sinds 2009 actief is op het gebied van objectbeveiliging, havenbeveiliging, ISPS, mobilesurveillance, winkelsurveillance, HVO’s en nog vele meer."
      />
      <div className='security-banner'>
        <div className='security-banner-image'>
          <img src={securityBanner.plaatje.fields.file.url} alt={securityBanner.plaatje.fields.description} />
        </div>

        <div className='security-banner-content'>
          <div className='security-banner-content-text'>
            <h1 className='security-banner-content-text-title'>{securityBanner.titel}</h1>
            {richTextToHtml(securityBanner.tekst.content, "security-banner-content-text-text")}
          </div>

          <div className='security-banner-content-footer'>
            <div className='security-banner-content-footer-button'>
              <Button
                color={"lightGrey"}
                onClick={() => {
                  navigate(`/${securityBanner.secundaireLink[0]}`);
                }}
                title={securityBanner.secundaireLink[1]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='security-line'></div>

      <div className='security-inhoud'>
        <div className='security-inhoud-image'>
          <img src={pageContent.inhoudPlaatje.fields.file.url} alt={pageContent.inhoudPlaatje.fields.description} />
        </div>

        <div className='security-inhoud-content'>
          <h1 className='security-inhoud-content-title'>{pageContent.inhoudTitel}</h1>
          {richTextToHtml(pageContent.inhoudTekst.content, "security-inhoud-content-text")}
        </div>
      </div>
      <div className='security-line'></div>

      <div className='security-bedrijven'>
        <h1 className='security-bedrijven-title'>{pageContent.bedrijvenTitel}</h1>
        <div className='security-bedrijven-overzicht'>
          {
            pageContent.bedrijvenLogos.map((logo, i) => (
              <div className='security-bedrijven-image' key={i}>
                <img src={logo.fields.file.url} alt={logo.fields.description} />
              </div>
            ))
          }
        </div>
      </div>

      <VacaturesLink
        content={vacaturesReclame}
      />

      <KeepUpdated
        content={aanmeldenVacatures}
      />

    </div>
  )
}

export default SecurityPage
