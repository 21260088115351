import React from "react";

import { BsArrowRight } from "react-icons/bs";

import "../../styles/components/atoms/Button.scss";

function Button({ color, title, icon, onClick, onlyText, success }) {
  return (
    <>
      {color === "lightGrey" && (
        <button
          className={`button button-${color}`}
          onClick={(e) => onClick && onClick(e)}
        >
          {title && <p className="button-title">{title}</p>}
          {icon && icon}
          {!onlyText && <BsArrowRight className="button-icon" /> }
        </button>
      )}
      {color === "darkGrey" && (
        <button
          className={`button button-${color}`}
          onClick={(e) => onClick && onClick(e)}
        >
          {title && <p className="button-title">{title}</p>}
          {icon && icon}
          {!onlyText && <BsArrowRight className="button-icon" /> }
        </button>
      )}
      {color === "yellow" && (
        <button
            className={`button button-${color}`}
            onClick={(e) => onClick && onClick(e)}
        >
          {title && <p className="button-title">{title}</p>}
          {icon && icon}
          {!onlyText && <BsArrowRight className="button-icon" /> }
        </button>
      )}
      {color === "green" && (
        <button
            className={`button button-${color}`}
            onClick={(e) => onClick && onClick(e)}
        >
          {title && <p className="button-title">{title}</p>}
          {icon && icon}
          {!onlyText && <BsArrowRight className="button-icon" /> }
        </button>
      )}
    </>
  );
}

export default Button;
