import React from 'react'
import { useNavigate } from 'react-router-dom';

import Button from '../atoms/Button'

import "../../styles/components/molecules/VacaturesLink.scss"

import { BsQuestionLg } from "react-icons/bs"

import { richTextToHtml } from '../../util/text';

const VacaturesLink = ({ content }) => {

  const navigate = useNavigate();

  return (
    <div className='vacatures-link'>
        <BsQuestionLg className='vacatures-link-question-mark' />
        <h1 className='vacatures-link-title'>
          {content.titel.split(" ").slice(0, -2).join(" ")}
          <span className='vacatures-link-title--underline'>{content.titel.split(" ").slice(-2).join(" ")}</span>
        </h1>
        {richTextToHtml(content.tekst.content, "vacatures-link-text")}
        <div className='vacatures-link-button'>
          <Button
            color="yellow"
            title={content.link && content.link[1]}
            onClick={() => {
              navigate(content.link && "/"+content.link[0])
            }}
          />
        </div>
    </div>
  )
}

export default VacaturesLink
