import { React, useState, useEffect } from 'react'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { formatDate } from '../util/date';
import { richTextToHtml } from '../util/text';
import { aanmeldenVacaturesSkeleton, nieuwsberichtSkeleton } from '../util/skeletons';

import Button from '../components/atoms/Button';
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/NieuwsberichtPage.scss";
import KeepUpdatedNews from '../components/molecules/KeepUpdatedNews';
import { getContent } from '../services/ContentService';

const NieuwsberichtPage = () => {

    let params = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [nieuwsberichten, setNieuwsberichten] = useState([])
    const [nieuwsbericht, setNieuwsbericht] = useState(nieuwsberichtSkeleton)
    const [socialMedia, setSocialMedia] = useState([])
    const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton)

    useEffect(() => {
        // Current news article
        getContent({
            content_type: 'nieuwsbericht',
            "sys.id": params.id
        })
        .then((response) => {
            if (response.items.length < 1) {
                navigate("/nieuwsberichten")
            }
            setNieuwsbericht(response.items[0].fields)
        })
        .catch(console.error);

        // Socials
        getContent({
            content_type: 'homePagina',
        })
        .then((response) => {
            setSocialMedia(response.items[0].fields.socials)
            setAanmeldenVacatures(response.items[0].fields.aanmeldenNieuwsberichten.fields)
        })
        .catch(console.error);

        // 3 news articles for the bottom of the page
        getContent({
            content_type: 'nieuwsbericht',
            "sys.id[nin]": params.id,
            limit: 3,
        })
        .then((response) => {
            setNieuwsberichten(response.items)
        })
        .catch(console.error);
    }, [pathname, navigate, params.id])

  return (
    <div className='nieuwsbericht'>
        <DocumentHead
            title={`${nieuwsbericht.titel} | Ageras`}
            metaDescription={`${nieuwsbericht.inleiding && nieuwsbericht.inleiding.slice(0, 150)}...`}
        />
        <div className='nieuwsbericht-container--banner'>
            <Zoom>
                <picture className='nieuwsbericht-image-container'>
                    <source media="(max-width: 800px)" src={nieuwsbericht.plaatje.length > 0 ? nieuwsbericht.plaatje[0].fields.file.url : undefined} />
                    <img
                        src={nieuwsbericht.plaatje.length > 0 ? nieuwsbericht.plaatje[0].fields.file.url : undefined}
                        alt="Banner"
                        draggable="false"
                    />
                </picture>
            </Zoom>
        </div>
        <div className='nieuwsbericht-container'>
            <div className='nieuwsbericht-header-container'>
                <span className='nieuwsbericht-header-date'>{formatDate(nieuwsbericht.datum)}</span>
                <h1 className='nieuwsbericht-header-title'>{nieuwsbericht.titel}</h1>
            </div>
        </div>
        <div className='nieuwsbericht-container'>
            <p><b>{nieuwsbericht.inleiding}</b></p>
        </div>
        <div className='nieuwsbericht-container'>
            {richTextToHtml(nieuwsbericht.alinea1.content, "nieuwsbericht-alinea-container")}
        </div>
        {nieuwsbericht.plaatje.length > 1  && (
            <div className='nieuwsbericht-container'>
            <Zoom>
                <picture className='nieuwsbericht-image-container'>
                    <img
                        src={nieuwsbericht.plaatje[1].fields.file.url}
                        alt={nieuwsbericht.plaatje[1].fields.description}
                        draggable="false"
                    />
                </picture>
            </Zoom>
        </div>
        )}
        <div className='nieuwsbericht-container nieuwsbericht-container-text'>
            {richTextToHtml(nieuwsbericht.alinea2.content, "nieuwsbericht-alinea-container")}
        </div>
        {nieuwsbericht.plaatje.length > 2 && (
            <div className='nieuwsbericht-container'>
                <div className='nieuwsbericht-image-grid'>
                    {nieuwsbericht.plaatje.slice(2).map((img, i) => (
                        <Zoom key={i}>
                            <picture className='nieuwsbericht-image-grid-item'>
                                <img
                                    src={img.fields.file.url}
                                    alt={img.fields.description}
                                    draggable="false"
                                />
                            </picture>
                        </Zoom>
                    ))}
                </div>
            </div>
        )}

        <div className='nieuwsbericht-container'>
            <KeepUpdatedNews
                content={aanmeldenVacatures}
            />
            <div className='nieuwsbericht-socials'>
                <h1 className='nieuwsbericht-socials-titel'>Volg ons op</h1>
                <div className='nieuwsbericht-socials-items'>
                    {
                        socialMedia.map((item, index) => (
                            <a href={item.fields.link} rel="noreferrer" target='_blank' className="nieuwsbericht-socials-item-link" key={index}>
                                <div className='nieuwsbericht-socials-item' key={index}>
                                    <img className="nieuwsbericht-socials-item-logo" src={item.fields.logo.fields.file.url} alt={item.fields.logo.fields.description} />
                                    {item.fields.titel}
                                </div>
                            </a>
                        ))
                    }
                </div>
            </div>
        </div>

        <div className='nieuwsbericht-container'>
            <div className='nieuwsbericht-more'>
                <h1 className='nieuwsbericht-more-title'>Meer nieuwsberichten</h1>
                <div className='nieuwsbericht-more-grid'>
                    {nieuwsberichten.map((item, i) => (
                    <div
                        key={i}
                        className='nieuwsbericht-more-item'
                    >
                        <div className='nieuwsbericht-more-item-image'>
                            <img
                                src={item.fields.plaatje[0].fields.file.url}
                                alt={item.fields.plaatje[0].fields.description}
                                onClick={() => {
                                    navigate(`/nieuwsberichten/${item.sys.id}`);
                                }}
                            />
                        </div>
                        <div className='nieuwsbericht-more-item-inhoud'>
                        <h1
                            className='nieuwsbericht-more-item-title'
                            onClick={() => {
                                navigate(`/nieuwsberichten/${item.sys.id}`);
                            }}
                        >{item.fields.titel}</h1>
                        <span className='nieuwsbericht-more-item-datum'>{formatDate(item.fields.datum)}</span>
                        <p className='nieuwsbericht-more-item-text'>{item.fields.inleiding}</p>
                        <Link
                            className='nieuwsbericht-more-item-link'
                            to={`/nieuwsberichten/${item.sys.id}`}
                        >
                            Lees meer
                        </Link>
                        </div>
                    </div>
                    ))}
                </div>
                <Button
                    title="Alle nieuwsberichten"
                    color={"yellow"}
                    onClick={() => {
                        navigate("/nieuwsberichten");
                    }}
                />
            </div>
        </div>
    </div>
  )
}

export default NieuwsberichtPage
