import DOMPurify from 'dompurify';

export const createTextStyling = function (text, marks, index) {
    if (marks.length > 0) {
        if (marks.length > 1) {
            switch (marks[0].type) {
            case 'bold':
                return <b key={index}>{createTextStyling(text, marks.slice(1), index)}</b>
            case 'italic':
                return <i key={index}>{createTextStyling(text, marks.slice(1), index)}</i>
            case 'underline':
                return <u key={index}>{createTextStyling(text, marks.slice(1), index)}</u>
            default:
                return null;
            }
        } else {
            const sanitizedValue = DOMPurify.sanitize(text, { ALLOWED_TAGS: ['br'] });
            const sanitizedHtml = sanitizedValue.replace(/\n/g, '<br />');
            switch (marks[0].type) {
            case 'bold':
                return <b key={index} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
            case 'italic':
                return <i key={index} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
            case 'underline':
                return <u key={index} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
            default:
                return null;
            }
        }
    } else {
        const sanitizedValue = DOMPurify.sanitize(text, { ALLOWED_TAGS: ['br'] });
        const sanitizedHtml = sanitizedValue.replace(/\n/g, '<br />');
        return <span key={index} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }
  }

export const richTextToHtml = function richTextToHtml (richText, itemClass = null) {
    return richText.map((item, index) => {
        switch (item.nodeType) {
            case "paragraph":
                return (
                    <p key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </p>
                );
            case "heading-1":
                return (
                    <h1 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h1>
                );
            case "heading-2":
                return (
                    <h2 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h2>
                );
            case "heading-3":
                return (
                    <h3 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h3>
                );
            case "heading-4":
                return (
                    <h4 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h4>
                );
            case "heading-5":
                return (
                    <h5 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h5>
                );
            case "heading-6":
                return (
                    <h6 key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </h6>
                );
            case "unordered-list":
                return (
                    <ul key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </ul>
                );
            case "ordered-list":
                return (
                    <ol key={index} className={itemClass && itemClass}>
                    { richTextToHtml(item.content) }
                    </ol>
                );
            case "list-item":
                return (
                    <li key={index} className={itemClass && itemClass}>{ richTextToHtml(item.content) }</li>
                )
            case 'text':
                return createTextStyling(item.value, item.marks, index)
            default:
                return null;
        }
    });
}
