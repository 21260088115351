import { React, useState, useEffect, useRef, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";

import { richTextToHtml } from '../util/text';
import { formatDate } from '../util/date';
import { vacatureSkeleton } from '../util/skeletons';

import "../styles/pages/VacatureInfoPage.scss";

import { BsArrowRight } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosTime } from "react-icons/io"
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa"
import { GiHandTruck } from "react-icons/gi"
import { TiLocationArrow } from "react-icons/ti"

import Video from '../components/atoms/Video';
import Button from '../components/atoms/Button';
import Loading from '../components/atoms/Loading';
import DocumentHead from "../components/atoms/DocumentHead";

import { telefoonRegex } from '../util/validate';

import { sendSollicitatieMail } from '../services/MailService';
import { getContent } from '../services/ContentService';

const sollicitatieValues = {
    vacature_naam: "",
    naam: "",
    telefoon: "",
    email: "",
    motivatie: "",
}

const VacatureInfoPage = () => {
    const SUPPORTED_FILE_TYPES = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]

    const { pathname } = useLocation();
    const navigate = useNavigate();
    let params = useParams();
    const recaptchaRef = useRef();

    const [vacature, setVacature] = useState(vacatureSkeleton);
    const [vacatureId, setVacatureId] = useState(null);
    const [loadingVacature, setLoadingVacature] = useState(false);
    const [nextVacature, setNextVacature] = useState(vacatureSkeleton);
    const [loadingNextVacature, setLoadingNextVacature] = useState(false);
    const [sollicitatieForm, setSollicitatieForm] = useState(sollicitatieValues);
    const [sollicitatieSuccess, setSollicitatieSuccess] = useState(false);
    const [sollicitatieError, setSollicitatieError] = useState(false);
    const [sollicitatieLoading, setSollicitatieLoading] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState("")
    const [cv, setCv] = useState(null);

    const getRandomVacature = (mainVacature) => {
        getContent({
            content_type:"vacature",
            "fields.type": mainVacature.type,
            "limit": 0,
        })
            .then((response) => {
                const random = Math.floor(Math.random() * (response.total - 1))
                getContent({
                    content_type: "vacature",
                    "fields.titel[nin]": mainVacature.titel,
                    "fields.type": mainVacature.type,
                    "skip": random,
                    "limit": 1,
                })
                    .then((response) => {
                        if (response.items.length > 0) {
                            response.items[0].fields = {...response.items[0].fields, id: response.items[0].sys.id}
                            setNextVacature(response.items[0].fields)
                        } else {
                            setNextVacature(vacatureSkeleton)
                        }
                        setLoadingNextVacature(false);
                    })
                    .catch(console.error);
            })
            .catch(console.error);
    }

    const handleOnChangeSollicitatie = useCallback((e) => {
        const { name } = e.target;
        let { value } = e.target

        setSollicitatieForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    }, [setSollicitatieForm])

    const sollicitatieSchema = yup.object().shape({
        naam: yup.string()
          .required("Vul een naam in"),
        email: yup.string()
          .email("Vul een geldig email adres in")
          .required("Vul een email adres in"),
        telefoon: yup.string()
          .nullable()
          .default(undefined)
          .transform((value) => value === "" ? null : value)
          .matches(telefoonRegex, "Vul een geldig telefoonnummer adres in"),
        motivatie: yup.string()
          .required("Vul een motivatie in"),
        cv: yup.mixed()
          .test("fileType", "Kies een word of pdf bestand", value => (value.length > 0) && SUPPORTED_FILE_TYPES.includes(value[0].type))
          .test("fileSize", "Het bestand is te groot", value => (value.length > 0) && value[0].size <= 2000000),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(sollicitatieSchema),
    })

    const handleOnSubmitSollicitatie = async (data) => {
        setSollicitatieLoading(true);
        setRecaptchaError("");

        const token = await recaptchaRef.current.getValue();
        if (!token) {
            setRecaptchaError("Vul de recaptcha in");
            setSollicitatieLoading(false);
            return;
        }
        data = {...data, token}

        const vacature_naam = sollicitatieForm.vacature_naam;
        data = {...data, vacature_naam}
        delete data["cv"];

        const fd = new FormData();
        fd.append("data", cv);
        fd.append("mail", JSON.stringify(data));

        const id = parseInt(params.id);
        if (!isNaN(id)) {
            sendSollicitatieMail(fd).then(async result => {
                if (result.status) {
                    reset();
                    sollicitatieValues.vacature_naam = vacature_naam;
                    setSollicitatieForm(sollicitatieValues);
                    setSollicitatieSuccess(true);
                    await recaptchaRef.current.reset();
                    setCv(null)

                    setTimeout(() => {
                        setSollicitatieSuccess(false);
                    }, 60000)
                } else {
                    setSollicitatieError(true);
                }
                setSollicitatieLoading(false);
            })
        } else {
            setSollicitatieLoading(false);
            setSollicitatieError(true);
        }
    }

    useEffect(() => {
        const id = params.id;
        if (id) {
            setLoadingVacature(true);
            setLoadingNextVacature(true);
            setVacatureId(id);

            getContent({
                content_type:"vacature",
                'sys.id':id
              })
                .then((response) => {
                    if (response.items.length <= 0) {
                        navigate("/vacatures")
                    }
                    setVacature(response.items[0].fields);
                    handleOnChangeSollicitatie({target: {name: "vacature_naam", value: response.items[0].fields.titel}})
                    setLoadingVacature(false);

                    getRandomVacature(response.items[0].fields)
                })
                .catch(console.error);
        } else {
            navigate("/vacatures")
        }
    }, [pathname, handleOnChangeSollicitatie, navigate, params.id]);

return (
    <div className='vacature'>
        <DocumentHead
            title={`${vacature.titel} | Ageras`}
            metaDescription=""
        />
        <div className='vacature-overzicht'>
            <div className='vacature-info'>
                <div className='vacature-info-content'>
                    {
                        (loadingVacature) ?
                        (
                            <Loading />
                        )
                        :
                        (
                            <>
                            <h1 className='vacature-info-title'>{vacature.titel}</h1>

                            <ul className='vacature-info-list'>
                                <li className='vacature-info-list-item--less-space'>
                                    <FaMapMarkerAlt />
                                    <p>{vacature.plaats}</p>
                                </li>
                                <li className='vacature-info-list-item--less-space'>
                                    {(vacature.type === "Services") ? <GiHandTruck /> : <AiFillSafetyCertificate />}
                                    <p>{vacature.type}</p>
                                </li>
                                <li className='vacature-info-list-item--less-space'>
                                    <IoIosTime />
                                    <p>{vacature.urenPerWeek} uur per week</p>
                                </li>
                            </ul>

                            <div className='vacature-info-beschrijving'>
                                <p className='vacature-info-subtitle'>Wat ga jij doen?</p>
                                {richTextToHtml(vacature.watGaJijDoen.content, "vacature-info-beschrijving-text")}
                            </div>

                            <div className='vacature-info-bieden'>
                                <p className='vacature-info-subtitle'>Wat wij bieden</p>
                                <ul className='vacature-info-list'>
                                    {vacature.watWijBieden.map((item, i) => (
                                        <li key={i} className='vacature-info-list-item'>
                                            <TiLocationArrow />
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className='vacature-info-profiel'>
                                <p className='vacature-info-subtitle'>Gewenst profiel</p>
                                    <ul className='vacature-info-list'>
                                    {vacature.gewenstProfiel.map((item, i) => (
                                        <li key={i} className='vacature-info-list-item'>
                                            <TiLocationArrow />
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <p className='vacature-info-datum'>{formatDate(vacature.datum)}</p>
                            </>
                        )
                    }
                </div>
            </div>
            <div className='vacature-form'>
                <form
                    method='POST'
                    onSubmit={handleSubmit(handleOnSubmitSollicitatie)}
                >
                    {
                        (sollicitatieSuccess) ?
                        (
                            <>
                                <h1 className='vacature-form-title'>Je sollicitatie is gelukt!🎉</h1>
                                <h3>Je krijgt zo snel mogelijk reactie.</h3>
                            </>
                        )
                        :
                        (
                            <>
                                <h1 className='vacature-form-title'>Solliciteer</h1>
                                {(sollicitatieError) && (
                                    <h3 className='vacature-form-error'>Oops! Er is iets foutgegaan.</h3>
                                )}
                            </>
                        )
                    }
                    <div className='vacature-form-section'>
                        <div className='vacature-form-section-text'>
                            <label>Naam*:</label>
                            <p className='vacature-form-error'>{errors.naam?.message}</p>
                        </div>
                        <input
                            type="text"
                            name="naam"
                            {...register("naam")}
                            onChange={(e) => {
                                handleOnChangeSollicitatie({target: {name: e.target.name, value: e.target.value}})
                            }}
                        />
                    </div>
                    <div className='vacature-form-section'>
                        <div className='vacature-form-section-text'>
                            <label>Email*:</label>
                            <p className='vacature-form-error'>{errors.email?.message}</p>
                        </div>
                        <input
                            type="text"
                            name="email"
                            {...register("email")}
                            onChange={(e) => {
                                handleOnChangeSollicitatie({target: {name: e.target.name, value: e.target.value}})
                            }}
                        />
                    </div>
                    <div className='vacature-form-section'>
                        <div className='vacature-form-section-text'>
                            <label>telefoon:</label>
                            <p className='vacature-form-error'>{errors.telefoon?.message}</p>
                        </div>
                        <input
                            type="tel"
                            name="telefoon"
                            {...register("telefoon")}
                            onChange={(e) => {
                                handleOnChangeSollicitatie({target: {name: e.target.name, value: e.target.value}})
                            }}
                        />
                    </div>
                    <div className='vacature-form-section'>
                        <div className='vacature-form-section-text'>
                            <label>motivatie*:</label>
                            <p className='vacature-form-error'>{errors.motivatie?.message}</p>
                        </div>
                        <textarea
                            name="motivatie"
                            {...register("motivatie")}
                            onChange={(e) => {
                                handleOnChangeSollicitatie({target: {name: e.target.name, value: e.target.value}})
                            }}
                        ></textarea>
                    </div>

                    <p className='vacature-form-error'>{recaptchaError}</p>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />

                    <div className='vacature-form-buttons'>
                        <div className='vacature-form-input-file'>
                            <p className='vacature-form-error'>{errors.cv?.message}</p>
                            <label htmlFor="cv">Selecteer cv</label>
                            <input
                                type="file"
                                name="cv"
                                id="cv"
                                {...register("cv")}
                                onChange={(e) => {
                                    setCv(e.target.files[0])
                                }}
                            />
                        </div>

                        {
                            (sollicitatieLoading) ?
                            (
                                <Button
                                    icon={<Loading />}
                                    color="lightGrey"
                                    onlyText={true}
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                />
                            )
                            :
                            (sollicitatieSuccess) ?
                            (
                                <Button
                                    icon={
                                        <svg className="button-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <path className="button-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                        </svg>
                                    }
                                    color="green"
                                    onlyText={true}
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                />
                            )
                            :
                            (
                                <Button
                                    title="Verstuur"
                                    color="lightGrey"
                                    onClick={() => {
                                        setSollicitatieSuccess(false);
                                        setSollicitatieError(false);
                                    }}
                                />
                            )
                        }
                    </div>
                    {
                        (cv) && (
                            <div className='vacature-form-selected-file'>
                                <IoCloseOutline
                                className='vacature-form-selected-file--icon'
                                onClick={() => {
                                    document.querySelector("#cv").value = null
                                    setCv(null);
                                }}
                                />
                                <p>{cv.name}</p>
                            </div>
                        )
                    }
                </form>
            </div>
        </div>

        <div className='vacature-video-container'>
            <div className='vacature-video'>
                <Video
                  src={require('../assets/videos/ageras_video.mp4')}
                  poster={require('../assets/images/ageras_video_poster.jpg')}
                />
            </div>
        </div>

        {
            (vacatureId) &&
            (
            <div className='vacature-menu-container'>
                <div className='vacature-menu'>
                    <div className='vacature-menu-terug'>
                        <Link to="/vacatures" className='vacature-menu-terug-content'>
                            <h1>Terug naar overzicht van vacatures</h1>
                            <BsArrowRight />
                        </Link>
                    </div>
                    {(nextVacature.titel !== "") &&
                    (
                    <div className='vacature-menu-next'>
                        <div className='vacature-menu-next-content'>
                        {
                            (loadingNextVacature) ?
                            (
                                <Loading />
                            )
                            :
                            (
                                <>
                                <h1 className='vacature-menu-next-subtitle'>Volgende vacature:</h1>
                                <h1 className='vacature-menu-next-title'>{nextVacature.titel}</h1>

                                <ul className='vacature-info-list'>
                                    <li className='vacature-info-list-item--less-space'>
                                        <FaMapMarkerAlt />
                                        <p>{nextVacature.plaats}</p>
                                    </li>
                                    <li className='vacature-info-list-item--less-space'>
                                        {(nextVacature.type === "Services") ? <GiHandTruck /> : <AiFillSafetyCertificate />}
                                        <p>{nextVacature.type}</p>
                                    </li>
                                    <li className='vacature-info-list-item--less-space'>
                                        <IoIosTime />
                                        <p>{nextVacature.urenPerWeek} uur per week</p>
                                    </li>
                                </ul>

                                <Button
                                    color="lightGrey"
                                    onClick={() => {
                                        navigate(`/vacatures/${nextVacature.id}`)
                                    }}
                                />
                                </>
                            )
                        }
                        </div>
                    </div>
                    )}
                </div>
            </div>

            )
        }

    </div>
  )
}

export default VacatureInfoPage
