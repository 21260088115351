import React, { useState } from "react";

import "../../styles/components/atoms/Video.scss";

import Button from "../atoms/Button";

function Video({ src, poster }) {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const toggleVideo = () => {
        const video = document.getElementById('video');
        setIsVideoPlaying(video.paused || video.ended)

        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }

        video.addEventListener('ended', (event) => {
          setIsVideoPlaying(false)
        });
       }
  return (
    <div className="video">
        <video id='video' muted poster={poster}>
          <source src={src} type='video/mp4'></source>
        </video>

        <div className={`video-button ${(isVideoPlaying) && "video-button--playing"}`}>
          <Button
            title={isVideoPlaying ? "Pauzeer video" : "Bekijk video"}
            color="darkGrey"
            onlyText={true}
            onClick={() => {toggleVideo()}}
          />
        </div>
    </div>
  );
}

export default Video;
