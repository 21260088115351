import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseOutline } from "react-icons/io5";

import AgerasLogoBlack from "../../assets/images/ageras_logo_black.svg";

import "../../styles/components/organisms/Navbar.scss";
import Input from '../atoms/Input';
import { getContent } from '../../services/ContentService';

const Navbar = () => {

  const location = useLocation();
  const navigate = useNavigate();
  let routeLinks = "";

  const [openSidebar, setOpenSidebar] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [routeNames, setRouteNames] = useState([]);
  const [vacatureTitle, setVacatureTitle] = useState("");

  useEffect(() => {
    let path = window.location.pathname.split("/").filter(element => element);
    setRouteNames(path);

    let items = [...path];
    items = items.map((item, i) => {
      if (i > 0 && items[i-1] === "vacatures") {
          getContent({
            content_type:"vacature",
            'sys.id': item
          }).then((response) => {
            let items = [...path];
            items = items.map((item, j) => {
              return (j > 0 && items[j-1] === "vacatures") ?
                  response.items[0].fields.titel :
                  item.charAt(0).toUpperCase() + item.slice(1)
            })
            setRouteNames(items);
          })
      } else if (i > 0 && items[i-1] === "nieuwsberichten") {
        getContent({
          content_type: "nieuwsbericht",
          'sys.id': item
        }).then((response) => {
            let items = [...path];
            items = items.map((item, j) => {
              return (j > 0 && items[j-1] === "nieuwsberichten") ?
                  response.items[0].fields.titel :
                  item.charAt(0).toUpperCase() + item.slice(1)
            })
            setRouteNames(items);
          })
      } else if (item === "overons") {
        return "Over ons"
      } else {
        return item.charAt(0).toUpperCase() + item.slice(1);
      }
      return ""
    })
    setRouteNames(items);

    setRoutes(path);
    setOpenSidebar(false);
  }, [location]);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-section'>
          <Link to="/">
            <img src={AgerasLogoBlack} alt='Ageras Logo' className='navbar-section-logo' />
          </Link>

          <div className='navbar-links'>
            <Link to='/overons'>Over ons</Link>
            <Link to='/security'>Security</Link>
            <Link to='/services'>Services</Link>
            <Link to='/nieuwsberichten'>Nieuwsberichten</Link>
            <Link to='/contact'>Contact</Link>
          </div>

          <div className='navbar-hamburger-menu'>
            <GiHamburgerMenu
              className='navbar-hamburger-icon'
              onClick={() => {
                setOpenSidebar(true);
              }}
            />
          </div>
        </div>

        <div className='navbar-links'>
          <Link to='/vacatures' id='vacatures-link'>Vacatures</Link>
        </div>

        <div className='navbar-input'>
          <Input
            placeholder="Zoek naar vacatures"
            value={vacatureTitle}
            onChange={(e) => {
              setVacatureTitle(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && vacatureTitle !== "") {
                navigate(`/vacatures?title=${vacatureTitle}&scroll=true`)
                setVacatureTitle("");
              }
            }}
            onClick={() => {
              navigate(`/vacatures?title=${vacatureTitle}`)
              setVacatureTitle("");
            }}
          />
        </div>
      </nav>

      <div className={`sidebar ${openSidebar && "sidebar-open"}`}>
        <div className='sidebar-header'>
          <Link to="/">
            <img src={require("../../assets/images/ageras_logo_mail.png")} alt='Ageras Logo' className='navbar-section-logo' />
          </Link>
          <IoCloseOutline
            className='sidebar-header-icon'
            onClick={() => {
              setOpenSidebar(false);
            }}
          />
        </div>

        <div className='sidebar-input'>
          <Input
            placeholder="Zoek naar vacatures"
            value={vacatureTitle}
            onChange={(e) => {
              setVacatureTitle(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && vacatureTitle !== "") {
                navigate(`/vacatures?title=${vacatureTitle}&scroll=true`)
                setVacatureTitle("");
              }
            }}
            onClick={() => {
              navigate(`/vacatures?title=${vacatureTitle}`)
              setVacatureTitle("");
            }}
          />
        </div>

        <div className='sidebar-links'>
          <Link to='/overons'>Over ons</Link>
          <Link to='/security'>Security</Link>
          <Link to='/services'>Services</Link>
          <Link to='/nieuwsberichten'>Nieuwsberichten</Link>
          <Link to='/contact'>Contact</Link>
        </div>

        <div className='sidebar-links'>
          <Link to='/vacatures' id='vacatures-link'>Vacatures</Link>
          <Link to='/vacatures?type=Security'>Security</Link>
          <Link to='/vacatures?type=Services'>Services</Link>
        </div>
      </div>

      <div className='nav-route'>
        <p>
        {
          (routes.length > 0) && <><Link to="/">Home</Link> {'>'} </>
        }

        {
          routes.map((route, i) => {
            routeLinks += (i !== 0) ? "/" + route : route;

            return (i !== 0) ? <span key={i}> {'>'} <Link to={routeLinks}>{routeNames[i]}</Link></span> : <Link key={i} to={routeLinks}>{routeNames[i]}</Link>
          })
        }
        </p>
      </div>
    </>
  )
}

export default Navbar
