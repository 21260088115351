import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import "../styles/pages/AfmeldenPage.scss";

import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";
import { removeUser } from '../services/UserService';

const AfmeldenPage = () => {

    const [queryParams] = useState(new URLSearchParams(window.location.search));
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const afmelden = () => {
        setSuccess(null);

        const id = queryParams.get("id");
        const code = queryParams.get("code");
        const type = queryParams.get("type");

        removeUser(id, code, type).then(result => {
            if (result) {
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        });
    }

  return (
    <div className='afmelden'>
        <DocumentHead
            title="Afmelden | Ageras"
            metaDescription=""
        />
        <div className='afmelden-banner'>
        <div className='afmelden-banner-image'>
          <img src={require('../assets/images/ageras_team.jpg')} alt='' />
        </div>

        <div className='afmelden-banner-content'>
          <div className='afmelden-banner-content-text'>
          {(!success) ? (
            <>
            {(success === null) ? (
                <h1 className='afmelden-title'>Weet je zeker dat je wilt afmelden voor {queryParams.get("type")} meldingen?</h1>
            ) : (
                <h1 className='afmelden-title--error'>Oops er is iets foutgegaan, probeer het later nog eens</h1>
            )}
            <div className='afmelden-buttons--multiple'>
                <Button
                    color="yellow"
                    title="Terug naar home"
                    onlyText={true}
                    onClick={() => {navigate("/")}}
                />
                <Button
                    color="lightGrey"
                    title="Afmelden"
                    onlyText={true}
                    onClick={() => {afmelden()}}
                />
            </div>
            </>
        ) : (
            <>
            <h1 className='afmelden-title--success'>Je bent succesvol afgemeld</h1>
            <div className='afmelden-buttons'>
                <Button
                    color="yellow"
                    title="Terug naar home"
                    onlyText={true}
                    onClick={() => {navigate("/")}}
                />
            </div>
            </>
        )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AfmeldenPage
