import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { richTextToHtml } from '../util/text';
import { bannerSkeleton,
        pageSkeleton,
        vacaturesReclameSkeleton,
        aanmeldenVacaturesSkeleton
      } from '../util/skeletons';

import "../styles/pages/ServicesPage.scss";

import VacaturesLink from '../components/molecules/VacaturesLink';
import KeepUpdated from "../components/molecules/KeepUpdated";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";
import { getContent } from '../services/ContentService';

const ServicesPage = () => {

  const navigate = useNavigate();
  const [servicesBanner, setServicesBanner] = useState(bannerSkeleton);
  const [pageContent, setPageContent] = useState(pageSkeleton)
  const [vacaturesReclame, setVacaturesReclame] = useState(vacaturesReclameSkeleton);
  const [aanmeldenVacatures, setAanmeldenVacatures] = useState(aanmeldenVacaturesSkeleton);

  useEffect(() => {
    getContent({
      content_type: 'servicesPagina',
    })
      .then((response) => {
        setPageContent(response.items[0].fields)
        setServicesBanner(response.items[0].fields.banner.fields)
        setVacaturesReclame(response.items[0].fields.vacaturesReclame.fields)
        setAanmeldenVacatures(response.items[0].fields.aanmeldenVacatures.fields)
      })
      .catch(console.error);
  }, [])

  return (
    <div className='services'>
      <DocumentHead
        title="Services | Ageras"
        metaDescription="Ageras Services is de flexibele schil binnen de uitzendbranche. Van magazijnmedewerkers tot dakdekkers, geen enkele uitdaging is voor ons te veel."
      />
      <div className='services-banner'>
        <div className='services-banner-image'>
          <img src={servicesBanner.plaatje.fields.file.url} alt={servicesBanner.plaatje.fields.description} />
        </div>

        <div className='services-banner-content'>
          <div className='services-banner-content-text'>
            <h1 className='services-banner-content-text-title'>{servicesBanner.titel}</h1>
            {richTextToHtml(servicesBanner.tekst.content, "services-banner-content-text-text")}
          </div>

          <div className='services-banner-content-footer'>
            <div className='services-banner-content-footer-button'>
              <Button
                color={"lightGrey"}
                onClick={() => {
                  navigate(servicesBanner.secundaireLink[0]);
                }}
                title={servicesBanner.secundaireLink[1]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='services-line'></div>

      <div className='services-inhoud'>
        <div className='services-inhoud-image'>
          <img src={pageContent.inhoudPlaatje.fields.file.url} alt={pageContent.inhoudPlaatje.fields.description} />
        </div>

        <div className='services-inhoud-content'>
          <h1 className='services-inhoud-content-title'>{pageContent.inhoudTitel}</h1>
          {richTextToHtml(pageContent.inhoudTekst.content, "services-inhoud-content-text")}
        </div>
      </div>

      <div className='services-line'></div>

      <div className='services-bedrijven'>
        <h1 className='services-bedrijven-title'>{pageContent.bedrijvenTitel}</h1>

        <div className='services-bedrijven-overzicht'>
          {
            pageContent.bedrijvenLogos.map((logo, i) => (
              <div className='services-bedrijven-image' key={i}>
                <img src={logo.fields.file.url} alt={logo.fields.description} />
              </div>
            ))
          }
        </div>
      </div>

      <VacaturesLink
        content={vacaturesReclame}
      />

      <KeepUpdated
        content={aanmeldenVacatures}
      />

    </div>
  )
}

export default ServicesPage
