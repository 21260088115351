import React, { useRef, useState, useEffect } from 'react'

import { AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai"

import "../styles/pages/OverOnsPage.scss";

import VacaturesLink from "../components/molecules/VacaturesLink";

import Button from '../components/atoms/Button';
import DocumentHead from "../components/atoms/DocumentHead";

import { richTextToHtml } from '../util/text';
import { bannerSkeleton,
        overOnsPageSkeleton,
        vacaturesReclameSkeleton
        } from '../util/skeletons';
import { getContent } from '../services/ContentService';

const OverOnsPage = () => {
    const onsTeamRef = useRef(null);
    const [banner, setBanner] = useState(bannerSkeleton);
    const [pageContent, setPageContent] = useState(overOnsPageSkeleton)
    const [vacaturesReclame, setVacaturesReclame] = useState(vacaturesReclameSkeleton);

    const scrollToOnsTeam = () => {
        onsTeamRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        getContent({
          content_type: 'overOnsPagina',
        })
          .then((response) => {
            setPageContent(response.items[0].fields)
            setBanner(response.items[0].fields.banner.fields)
            setVacaturesReclame(response.items[0].fields.vacaturesReclame.fields)
          })
          .catch(console.error);
    }, [])

  return (
    <div className='over-ons'>
      <DocumentHead
        title="Over ons | Ageras"
        metaDescription="Ageras is uw betrouwbare, professionele en betrokken partner voor Security en Services"
      />
        <div className='over-ons-banner'>
        <div className='over-ons-banner-image'>
          <img src={banner.plaatje.fields.file.url} alt={banner.plaatje.fields.description} />
        </div>

        <div className='over-ons-banner-content'>
          <div className='over-ons-banner-content-text'>
            <h1 className='over-ons-banner-content-text-title'>{banner.titel}</h1>
            {richTextToHtml(banner.tekst.content, "over-ons-banner-content-text-text")}
          </div>

          <div className='over-ons-banner-content-footer'>
            <div className='over-ons-banner-content-footer-button'>
              <Button
                color={"lightGrey"}
                onClick={() => {
                  scrollToOnsTeam();
                }}
                title={banner.secundaireLink[0]}
              />
            </div>
          </div>
        </div>
      </div>

      <div ref={onsTeamRef} className="over-ons-container">
        <h1 className='over-ons-team-title'>{pageContent.teamTitel.split(" ").slice(0, -2).join(" ")}<span className='over-ons-team-title--underline'>{pageContent.teamTitel.split(" ").slice(-2).join(" ")}</span></h1>
        <div className='over-ons-team-container'>
            {
                pageContent.personen.map((persoon, i) => (
                    <div className='over-ons-team-card' key={i}>
                        <div className='over-ons-team-card-image'>
                            <img src={persoon.fields.foto.fields.file.url} alt={persoon.fields.foto.fields.description} />
                        </div>
                        <div className='over-ons-team-card-content'>
                            <h2>{persoon.fields.naam}</h2>
                            <p>{persoon.fields.titel}</p>
                            <div className='over-ons-team-card-socials'>
                                {
                                    persoon.fields.email && (
                                        <a href={`mailto: ${persoon.fields.email}`} alt="">
                                            <AiOutlineMail />
                                        </a>
                                    )
                                }
                                {
                                    persoon.fields.linkedin && (
                                        <a href={persoon.fields.linkedin} alt="" rel='noreferrer' target="_blank">
                                            <AiOutlineLinkedin />
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
      </div>

      <div className='over-ons-container--grid'>
        <div className='over-ons-sponsoren'>
            <h1>{pageContent.sponserTitel.split(" ").slice(0, -1).join(" ")} <span className='over-ons-team-title--underline'>{pageContent.sponserTitel.split(" ").slice(-1).join(" ")}</span></h1>
            <div className='over-ons-sponsoren-logos'>
                {
                    pageContent.sponserLogos.map((logo, i) => (
                        <a href={logo.fields.link} target="_blank" rel="noreferrer" key={i}>
                            <div className='over-ons-sponsoren-image'>
                                <img src={logo.fields.logo.fields.file.url} alt={logo.fields.logo.fields.description} />
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
        <div className='over-ons-sponsoren'>
            <h1>{pageContent.steunTitel.split(" ").slice(0, -1).join(" ")} <span className='over-ons-team-title--underline'>{pageContent.steunTitel.split(" ").slice(-1).join(" ")}</span></h1>
            <div className='over-ons-sponsoren-logos'>
                {
                    pageContent.steunLogos.map((logo, i) => (
                        <a href={logo.fields.link} target="_blank" rel="noreferrer" key={i}>
                            <div className='over-ons-sponsoren-image'>
                                <img src={logo.fields.logo.fields.file.url} alt={logo.fields.logo.fields.description} />
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
      </div>

      <div className='over-ons-line'></div>

      <div className='over-ons-container'>
        <h1>{pageContent.zakelijkTitel}</h1>
        <div className='over-ons-zakelijk'>
            <div className='over-ons-zakelijk-item'>
                {richTextToHtml(pageContent.zakelijkAgerasSecurity.content)}
            </div>
            <div className='over-ons-zakelijk-item'>
                {richTextToHtml(pageContent.zakelijkAgerasServices.content)}
            </div>
        </div>
      </div>

        <VacaturesLink
            content={vacaturesReclame}
        />
    </div>
  )
}

export default OverOnsPage
