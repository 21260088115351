import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import './App.scss';

import Home from "./pages/HomePage"
import Security from "./pages/SecurityPage"
import Services from "./pages/ServicesPage"
import Nieuwsberichten from "./pages/NieuwsberichtenPage"
import Nieuwsbericht from "./pages/NieuwsberichtPage"
import Vacatures from "./pages/VacaturesPage"
import VacatureInfoPage from "./pages/VacatureInfoPage"
import AfmeldenPage from "./pages/AfmeldenPage";
import OverOnsPage from "./pages/OverOnsPage";
import ContactPage from "./pages/ContactPage";

import Navbar from "./components/organisms/Navbar"
import Footer from "./components/organisms/Footer"

const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="App-content">
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/security" element={<Security />} />
          <Route path="/services" element={<Services />} />
          <Route path="/nieuwsberichten" element={<Nieuwsberichten />} />
          <Route path="/nieuwsberichten/:id" element={<Nieuwsbericht />} />
          <Route path="/overons" element={<OverOnsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/vacatures" element={<Vacatures />} />
          <Route path="/vacatures/:id" element={<VacatureInfoPage />} />
          <Route path="/afmelden" element={<AfmeldenPage />} />
        </Routes>
      </div>

      <Footer />
    </>
  )
}

const App = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <Main />
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
