import React from 'react'

import "../../styles/components/atoms/Input.scss";

import { BsArrowRight } from 'react-icons/bs'

const Input = ({ name, value, placeholder, register, onChange, onKeyDown, onClick, success, onlyText }) => {
  return (
    <div
      className={`input ${success&& "input--success"}`}
    >
      {
        (success) ?
        (
        <svg className="input-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <path className="input-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
        )
        :
        (
          <>
            <input
              name={name}
              type="text"
              placeholder={placeholder}
              value={value}
              {...register}
              onChange={(e) => { onChange && onChange(e); }}
              onKeyDown={(e) => { onKeyDown && onKeyDown(e); }}
            />
            {
              (!onlyText) &&
              (
                <BsArrowRight
                  className='input-icon'
                  onClick={(e) => { onClick && onClick(e); }}
                />
              )
            }
          </>
        )
      }
    </div>
  )
}

export default Input
