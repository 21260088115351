import React from 'react'
import { Link } from "react-router-dom"

import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai"

import AgerasLogoWhite from "../../assets/images/ageras_logo_white.svg";

import "../../styles/components/organisms/Footer.scss"

import agerasCert from "../../assets/images/ageras_cert.png";
import vebLogo from "../../assets/images/veb_logo.png";

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footer-content'>

            <div className='footer-logos'>
                <div className='footer-image'>
                    <img src={AgerasLogoWhite} alt="" />
                </div>
                <div className='footer-logos-flex'>
                    <div className='footer-image'>
                        <img src={agerasCert} alt="" />
                    </div>
                    <div className='footer-image'>
                        <img src={vebLogo} alt="" />
                    </div>
                </div>
            </div>

            <div className='footer-menu'>
                <h1 className='footer-title'>Menu</h1>
                <div className='footer-lists'>
                    <ul className='footer-list--menu'>
                        <li><Link className='footer-list-link' to="/">Home</Link></li>
                        <li><Link className='footer-list-link' to="/overons">Over ons</Link></li>
                        <li><Link className='footer-list-link' to="/security">Security</Link></li>
                        <li><Link className='footer-list-link' to="/services">Services</Link></li>
                        <li><Link className='footer-list-link' to="/nieuwsberichten">Nieuwsberichten</Link></li>
                        <li><Link className='footer-list-link' to="/contact">Contact</Link></li>
                    </ul>
                    <ul className='footer-list'>
                        <li className='footer-list-item--vacatures'><Link className='footer-list-link' to="/vacatures">Vacatures</Link></li>
                        <li><Link className='footer-list-link' to="/vacatures?type=Security">Security</Link></li>
                        <li><Link className='footer-list-link' to="/vacatures?type=Services">Services</Link></li>
                    </ul>
                </div>
            </div>

            <div className='footer-contact'>
                <h1 className='footer-title'>Contact</h1>
                <ul className='footer-list'>
                    <li className='footer-list-item--contact'>
                        <AiOutlineMail className='footer-list-item-icon' />
                        <a href='mailto:werken@ageras.nl'>werken@ageras.nl</a>
                    </li>
                    <li className='footer-list-item--contact'>
                        <AiOutlinePhone className='footer-list-item-icon' />
                        <a href='tel:085 105 41 00'>085 105 41 00</a>
                    </li>
                </ul>
            </div>
        </div>
        <div className='footer-made-by'>
            <p>Gemaakt door: <a href='https://toinevanwonderen.nl/'>Toine van Wonderen</a></p>
            <p>© 2024 Ageras</p>
        </div>
    </footer>
  )
}

export default Footer
