import React, { useState } from 'react'

import "../../styles/components/molecules/KeepUpdatedNews.scss"

import { addUser } from '../../services/UserService';

import Input from '../atoms/Input';
import Loading from '../atoms/Loading';
import { emailRegex } from '../../util/validate';

const KeepUpdated = ({ content }) => {

  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveUser = async () => {
    setWarning("");
    setSuccess(false);
    setLoading(true);

    if (email.toLowerCase().match(emailRegex)) {
      addUser(email, "news").then(result => {
        if (result === 409) {
          setWarning("Email is al aangemeld")
        } else {
          setEmail("")
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 10000);
        }
        setLoading(false);
      })
    } else {
      setWarning("Vul een geldig email adres in")
      setLoading(false);
    }
  }

  return (
    <div className='keep-updated-news'>
          {
            (!success) ?
            (
              <h1 className='keep-updated-news-title'>
                {content.titel}
              </h1>
            )
            :
            (
              <>
                <h1 className='keep-updated-news-title'>{content.succesTitel}</h1>
                <span className='keep-updated-news-text'>{content.succesTekst}</span>
              </>
            )
          }

      <p className='keep-updated-news-warning'>{warning}</p>
      <p className='keep-updated-news-success'>{success}</p>
      <div
        className={`keep-updated-news-input ${warning !== "" && "keep-updated-news-input--error"} ${success && "keep-updated-news-input--success"}`}
      >
            <Input
              name="email"
              onlyText={loading && true}
              placeholder={content.placeholder}
              value={email}
              onChange={(e) => {setEmail(e.target.value)}}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  saveUser(e);
                }
              }}
              onClick={(e) => { saveUser(e); }}
              success={success}
            />
            {
            (loading) &&
              (
              <Loading />
              )
          }
      </div>
    </div>
  )
}

export default KeepUpdated
