import { Helmet } from "react-helmet-async";

function DocumentHead({title, metaDescription}) {

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {metaDescription !== "" &&
            <meta name="description" content={metaDescription}/>
        }

        {/* optional meta tags */}
        <meta charSet="utf-8" />
      </Helmet>
    </>
  );

}

export default DocumentHead;
